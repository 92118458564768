import React from "react";

class DsInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.setAvailableDamageSets = this.setAvailableDamageSets.bind(this);
    }

    componentDidMount() {
        this.setAvailableDamageSets();
        if (this.props.existingValue) {
            this.setState({
                selected:this.props.existingValue
            })
        }
    }

    setAvailableDamageSets() {
        let options = this.props.damageSetData?.filter(item => {
            return item.type === this.props.type;
        });

        this.setState({
            options
        })
    }

    handleChange(e) {
        let damageSet = this.state.options?.find(item => {
            return item.id == e.target.value;
        });

        this.props.updateParent(this.props.name, damageSet);
    }

    render() {
        const options = this.state.options?.map((item) => {
            return <option key={this.props.damageId + '-' + item.id + '-' + this.props.type} value={item.id}>{item.code}-{item.name}</option>
        });

        return (
            <div className={"form-group"}>

                <select
                    name={this.props.name}
                    value={this.props.existingValue?.id}
                    onChange={this.handleChange}
                    className={"form-select text-capitalize ds-select " + this.props.name}
                    required={this.props.isRequired}
                >
                    <option value="">{this.props.label}</option>
                    {options}
                </select>
            </div>
        );
    }
}

DsInput.propTypes = {};

export default DsInput;

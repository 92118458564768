export const vinValidation = {
    vehicles: (vin) => {
        return vin && vin.length === 17;
    },
    container: (vin) => {
        return vin && vin.length === 11;
    },
    coils: () => {
        return true;
    },
    ingo: () => {
        return true;
    }
};
